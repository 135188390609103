import styled from '@emotion/styled'
import LinkFormatter from 'components/LinkFormatter'
import Title from 'components/Utilities/Title'

export const Articles = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const BackToAll = styled(LinkFormatter)``

export const BackToAllText = styled(BackToAll)`
  width: 100%;
  text-align: center;
  margin-bottom: 120px;
  font-size: 38px;
  color: ${props => props.theme.colors.primarygreen};

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: 22px;
  }
`

export const BlogSnippetTitle = styled(Title)`
  margin-top: 80px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 0;
    padding: 0 30px;
  }

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    padding: 0;
  }
`

export const BlogSnippetHeader = styled.div`
  width: 100%;
  position: relative;
  padding: 0;

  .AnimatedLine {
    bottom: initial;
    top: -15px;
    right: -185px;
    width: 100vw;

    @media (min-width: 600px) {
      right: -120px;
      width: 100%;
    }

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      top: -30px;
    }

    @media (min-width: ${props => props.theme.breakpoints.m}) {
      right: -78px;
    }

    @media (min-width: ${props => props.theme.breakpoints.xl}) {
      right: -60px;
    }

    @media (min-width: ${props => props.theme.breakpoints.xxl}) {
      right: 190px;
    }

    @media (max-width: 600px) {
      right: -40px;
      width: 100%;
    }
  }
`


export const EmptyArticlesContainer = styled.div`
  text-align: center;
  padding: 40px 0;
`