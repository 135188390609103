import React from 'react'
import PropTypes from 'prop-types'
import theme from 'utils/styles/theme'
import Container from 'components/Container'
import {
  Articles,
  BackToAllText,
  BlogSnippetTitle,
  BlogSnippetHeader,
  EmptyArticlesContainer,
} from './styles'
import ArticleTile from 'components/ArticleTile'
import AnimatedLine from 'components/AnimatedLine'
import LocalizedLink from 'components/LocalizedLink'
import LocaleContext from 'context/LocaleProvider'

const BlogSnippet = ({
  articles,
  query: { back_to, estimated_read_time, title },
  style,
}) => {

  const localeContext = React.useContext(LocaleContext)
  const i18n = localeContext.i18n[localeContext.locale]

  const path =
     i18n.locale === 'fr-ca' ? `blogue` : `blog`


  return articles?.length ? (
    <Container style={{ backgroundColor: theme.colors.lightblue, ...style }}>
      {title && (
        <BlogSnippetHeader>
          <AnimatedLine className="AnimatedLine" />
          <BlogSnippetTitle as="h2" type="h3">
            {title?.text}
          </BlogSnippetTitle>
        </BlogSnippetHeader>
      )}
      <Articles>
        {articles.map(article => {
          const node = article?.article?.document || article?.node

          return (
            <ArticleTile
              estimated_read_time={estimated_read_time}
              node={node}
            />
          )
        })}
        {back_to?.text ? (
          <BackToAllText pageHandle={path} pageType={'page'}>
            {back_to?.text}
          </BackToAllText>
        ) : (
          <></>
        )}
      </Articles>
    </Container>
  ) : (
    <Container style={{ backgroundColor: theme.colors.lightblue, ...style }}>
      <EmptyArticlesContainer>
        {back_to?.text ? (
          <BackToAllText pageHandle={path} pageType={'page'}>
            {back_to?.text}
          </BackToAllText>
        ) : (
          <></>
        )}
      </EmptyArticlesContainer>
    </Container>
  )
}

BlogSnippet.propTypes = {
  articles: PropTypes.array,
}

export default BlogSnippet
